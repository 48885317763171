@media only screen and (max-width: 1200px) {

  .mainWrapper {
    display: grid !important;
    padding: 0px !important;
    grid-template-columns: 1fr !important;
    height: auto !important;
  }

  .leftWrapper,
  .rightWrapper {
    display: block !important;
    padding: 0px 4rem !important;
  }

  .leftWrapper {
    grid-column-start: 1 !important;
    grid-column-end: 1 !important;
    grid-row-start: 2 !important;
    grid-row-end: 3 !important;
    overflow-y: visible !important;
    height: 100% !important;
  }

  .rightWrapper {
    grid-column-start: 1 !important;
    grid-column-end: 1 !important;
    grid-row-start: 1 !important;
    grid-row-end: 2 !important;
    height: 400px !important;
    display: flex !important;
    flex-direction: column !important;
  }

  .pageNavigationWrapper {
    grid-row-start: 1 !important;
    grid-row-end: 2 !important;
    margin-left: auto;
    margin-right: 1rem;
    -webkit-box-shadow: 0px 0px 21px -15px #2C2B2B;
    box-shadow: 0px 0px 21px -15px #2C2B2B;
  }

  .contentWrapper {
    height: auto !important;
  }

  .imageWrapper {
    display: flex !important;
    justify-content: center !important;
    height: 100% !important;
  }

  .imageWrapper>img {
    max-height: none !important;
    padding: 0px !important;
    width: 200px !important;
    height: 200px !important;
    margin: auto 0px !important;
    object-fit: contain !important;
  }

  .pageNrWrapper {
    display: none !important;
  }

}