@font-face {
  font-family: 'Noto IKEA';
  src: url('../../fonts/NotoSansIkea-Regular.woff') format('woff');
  font-display: fallback;
  font-weight: normal;
}

@font-face {
  font-family: 'Noto IKEA';
  src: url('../../fonts/NotoSansIkea-Bold.woff') format('woff');
  font-display: fallback;
  font-weight: bold;
}

* {
  font-family: 'Noto IKEA';
}

* {
  color: $blackColor;
}

.fSizeS {
  font-size: 12px;
}

.fSizeSM {
  font-size: 14px;
}

.fSizeM {
  font-size: 16px;
}

.fSizeB {
  font-size: 24px;
}

.fBold {
  font-weight: bold;
}

.fNormalWeight {
  font-weight: normal;
}

.fCenter {
  text-align: center;
}