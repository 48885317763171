@import "./styles/color.scss";
@import "./font.scss";

body {
  margin: 0px;
}

.p-chips-token {
  padding: 0px !important;
  margin: 2px 0px;
}