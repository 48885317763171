.button {
  height: 60px;
  width: 60px;
}

.flexWrapper {
  display: flex;
}

.inputColors {
  width: 200px;
  margin: 10px 0px;
}

.inputColors>input {
  margin: 0px 5px 0px 0px;
}