@import "../../../global/styles/color.scss";
@import "./responsive.module.scss";

.mainWrapper {
  display: grid;
  height: 100%;
  grid-template-columns: 1.5fr 1fr;
  gap: 1.5rem;
  padding: 0px 1.5rem;
}

.leftWrapper,
.rightWrapper {
  display: flex;
  flex-direction: column;
  padding: 1.5rem 0px;
  gap: 1.5rem;
}

.leftWrapper {
  gap: 1.5rem;
}

.cardWrapper {
  height: 600px;
}

.wrapper {
  flex-grow: 1;
  min-height: 10rem;
  background-color: $whiteColor;
}