@import "./../styles/color.scss";
@import "./../responsive.module.scss";

.navWrapper {
  background-color: $blackColor;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 3;
}

.logoWrapper {
  padding: 5px;
}

.logoWrapper svg {
  width: 100%;
}

.navWrapper ul {
  padding: 0px;
}

.navWrapper ul li {
  display: flex;
  justify-content: center;
  margin: 50px 0px;
}

.navWrapper ul li a svg {
  width: 100%;
  height: 30px;
}