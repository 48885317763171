@import "./responsive.module.scss";

.mainWrapper {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 30rem;
  gap: 1.5rem;
}

.leftWrapper {
  display: grid;
  grid-template-rows: 8rem 1fr;
  grid-template-columns: 1fr;
  margin: 1.5rem 0px 1.5rem 1.5rem;
  gap: 1.5rem;
}

.rightWrapper {
  margin: 1.5rem 1.5rem 1.5rem 0px;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.wrapper {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}