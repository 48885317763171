@media only screen and (max-width: 1200px) {

  .mainWrapper {
    display: flex !important;
    flex-direction: column !important;
    padding: 1.5rem !important;
    height: auto !important;
  }

  .leftWrapper,
  .rightWrapper {
    padding: 0px !important;
  }

  .rightWrapper>div {
    margin-bottom: 1.5rem !important;
  }
}