@import "../../../global/styles/color.scss";
@import "../../../global/styles/variables.scss";


.wrapper {
  display: flex;
  height: $pageHeight;
  margin: $pageMargin;
  gap: 10px;
}

.containers {
  background-color: $whiteColor;
  padding: 0px 10px 10px 10px;
  display: flex;
  flex-direction: column;
}

.cWizard {
  flex-basis: 0px;
  flex-grow: 4;
}

.cPreview {
  flex-basis: 0px;
  flex-grow: 3;
}