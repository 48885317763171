@media only screen and (max-width: 1200px) {

  .mainWrapper,
  .leftWrapper,
  .rightWrapper,
  .wrapper {
    display: flex !important;
    flex-direction: column !important;
  }

  .leftWrapper,
  .rightWrapper {
    margin: 0px 1.5rem 0px 1.5rem !important;
    gap: 0px !important;
  }

  .mainWrapper {
    padding: 1.5rem 0px !important;
  }

  .rightWrapper>div {
    margin: 0px 0px 1.5rem 0px !important;
  }

  .flex {
    padding: 0px 1.5rem !important;
  }

  .recentBadgeWrapper {
    height: 20rem !important;
    padding: 0px 1.5rem !important;
  }


}