@import "./../styles/color.scss";

.mainWrapper {
  width: 100%;
  padding: 0px 5px;
  display: flex;
  margin: 15px 0px;
}

.title {
  margin: 0px;
  padding-left: 0.5rem;
  background-color: $blackColor;
  color: $whiteColor;
  border: 1px solid black;
}

.flex {
  display: flex;
  background-color: $whiteColor;
}

.wrapper {
  width: 100%;
}

.imgWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  height: 6rem;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 5px 0px;
  background-color: $whiteColor;

}

.imgWrapper>svg {
  width: auto;
  margin: 0px 10px;
}

.imgBadge {
  height: 4rem;
  width: 4rem;
}