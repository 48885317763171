@media only screen and (max-width: 1200px) {
  .mainWrapper {
    display: block !important;
  }

  .navWrapper {
    display: none !important;
  }

  .headerWrapper {
    height: 5rem;
  }


}