@import "./../styles/color.scss";

.sMainWrapper {
  display: flex;
  height: 100px;
  margin: 20px 0px;
  -webkit-box-shadow: 0px 0px 21px -15px #2C2B2B;
  box-shadow: 0px 0px 21px -15px #2C2B2B;
  padding: 0px 10px;
  background: linear-gradient(70deg, $whiteColor 65%, $mainColor 35%);
}

.sContentWraper {
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  flex-basis: 0px;
}

.sImageWrapper {
  flex-grow: 1;
  flex-basis: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.sProfileWrapper {
  display: flex;
}

.sProfileWrapper>img {
  height: 15px;
}

.sProfileName {
  margin: 0px 8px;
}

.sButtonWrapper {
  display: flex;
  margin: auto 0px;
}

.sButtonWrapper>button {
  background: none;
  border: 0px;
  cursor: pointer;
  outline: 0px;
}


.goToBadge {
  background-color: $blackColor !important;
  border-radius: 8px;
  color: $whiteColor;
  margin: 5px auto 5px 0px;

}

.shareButton {
  margin-right: 5px;
}

.flex {
  display: flex;
  align-items: center;
}


.sBadgeCircleWrapper {
  display: flex;
  width: 70px;
  height: 70px;
  margin-left: 20px;
  border-radius: 100%;
}


.bMainWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bImageWrapper {
  width: 100%;
  background: $mainColor;
  max-height: 200px;
  padding: 30px;
}

.bContentWraper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: $whiteColor;
  padding: 0px 1.5rem;
}

.bProfileWrapper {
  display: flex;
}

.bProfileWrapper>img {
  height: 30px;
  width: 30px;
}

.bWrapper>div {
  margin: 2rem 0px;
}

.bButtonWrapper {
  display: flex;
  margin-bottom: 2rem;
  margin-top: 1rem;
}



.certification,
.heartButton,
.shareButton {
  display: flex;
  background: none;
  border: 0px;
  cursor: pointer;
  outline: 0px;
}

.certification>img,
.heartButton>img,
.shareButton>img {
  height: 2rem;
  width: 2rem;
  margin: 0px 0.4rem;
}

.certification {
  margin-left: auto;
}

.bDesc {
  margin-top: 0px;
}

.bImageWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bBadgeCircleWrapper {
  width: 200px;
  height: 200px;
}

.editBadge {
  display: flex;
  background: $blackColor;
  border: 0px;
  align-items: center;
  padding: 0.5rem;
}

.editBadge>img {
  height: 2rem;
  width: 2rem;
}

.buttonTitle {
  color: $whiteColor;
  margin: 0px 1rem;
}

@media only screen and (max-width: 1200px) {
  .buttonTitle {
    font-size: 12px !important;
    margin: 0px 0.2rem 0px 0px;
  }

  .editBadge>img {
    display: none;
  }

  .bButtonWrapper>button>img {
    height: 1.4rem;
    width: 1.4rem;
  }

  .bButtonWrapper {
    display: flex;
    align-items: center;
  }
}