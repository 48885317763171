@import "../../global/styles/color.scss";
@import "./responsive.module.scss";


.mainWrapper {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.leftWrapper,
.rightWrapper {
  display: flex;
  flex-direction: column;
  grid-row-start: 1;
  grid-row-end: 2;
  padding: 0px 100px;
  height: 100vh;
}

.leftWrapper {
  grid-column-start: 1;
  grid-column-end: 2;
  overflow-y: auto;
}

.rightWrapper {
  background-color: $mainColor;
  grid-column-start: 2;
  grid-column-end: 3;
}

.imageWrapper {
  margin: auto;
}

.imageWrapper>img {
  width: 100%;
  padding: 60px 0px 0px 0px;
  height: calc(100vh - 120px);
  max-height: 400px;
}

.pageNavigationWrapper {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 20px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.arrowWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  font-size: 25px;
  border: 0px;
  cursor: pointer;
  transition: 0.2s;
}

.arrowWrapper:hover {
  font-size: 28px;
}

.leftArrow {
  background-color: $mainColor;
}

.rightArrow {
  background-color: $whiteColor;
}

.pageNrWrapper {
  display: flex;
  width: 150px;
  align-items: center;
}

.pageNrWrapper>hr {
  width: 100%;
  height: 1px;
  background-color: $blackColor;
  border: 0px;
  margin: auto 20px;
}

.title {
  font-size: 38px;
}

.contentWrapper {
  margin-top: auto;
  height: 450px;
}

.buttonWrapper {
  display: flex;
  margin: 60px 0px;
  gap: 10px;
}

.buttonWrapper>button,
.buttonWrapper>a {
  text-decoration: none;
  padding: 20px 40px;
  border: 0px;
  cursor: pointer;
  transition: 0.2s;
}

.buttonWrapper>button:hover {
  transform: scale(1.1);
}

.dashboardButton {
  background-color: $mainColor;
}

.learnMoreButton {
  margin: auto;
}

.navbarWrapper {
  position: absolute;
  right: 20px;
  top: 10px;
  width: 400px;
  display: flex;
  margin-top: 10px;
}

.logo {
  position: absolute;
  top: 10px;
  left: 10px;
}