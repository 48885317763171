@import "../../global/styles/color.scss";
@import "./responsive.module.scss";



.mainWrapper {
  height: 100vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.leftWrapper,
.rightWrapper {
  display: flex;
  flex-direction: column;
  grid-row-start: 1;
  grid-row-end: 2;
  padding: 0px 100px;
  height: 100vh;
}

.leftWrapper {
  grid-column-start: 1;
  grid-column-end: 2;
  overflow-y: auto;
}

.rightWrapper {
  background-color: $mainColor;
  grid-column-start: 2;
  grid-column-end: 3;
}

.imageWrapper {
  margin: auto;
}

.imageWrapper>img {
  width: 100%;
  padding: 60px 0px 0px 0px;
  height: calc(100vh - 120px);
  max-height: 400px;
}

.pageNavigationWrapper {
  display: flex;
  justify-content: center;
  margin-top: auto;
  margin-bottom: 20px;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
}

.title {
  font-size: 38px;
}

.description {
  overflow-y: auto;
  max-height: 150px;
  padding-right: 1rem;
}

.contentWrapper {
  margin-top: auto;
  height: 550px;
}

.buttonWrapper {
  display: flex;
  margin: 40px 0px;
  gap: 10px;
}

.buttonWrapper>a {
  text-decoration: none;
  padding: 20px 40px;
  border: 0px;
  cursor: pointer;
  transition: 0.2s;
  background-color: #F3F3F3;
}

.buttonWrapper>div {
  margin-left: auto;
  gap: 50px;
}

.logo {
  position: absolute;
  top: 10px;
  left: 10px;
}

.message {
  margin-top: 100px;
}

.flex {
  display: flex;
  gap: 15px;
}

.infoLogo {
  width: 1.5rem;
}

.buttonLogo {
  width: 1.7rem;
  transition: 0.2s;
  cursor: pointer;
}

.buttonLogoWrapper {
  background: none;
  border: 0px;
}