@import "./../styles/color.scss";

.mainWrapper {
  background-color: $whiteColor;
  width: 100%;
  display: flex;
}

.wrapper {
  margin-left: 1rem;
  width: 100%;
}

.listWrapper {
  height: 200px;
  padding: 0px;
  overflow-y: auto;
  margin-right: 1rem;
}

.listWrapper>li {
  cursor: pointer;
  list-style-type: none;
  display: flex;
  align-items: center;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid black;
}

.profile {
  margin-right: 1rem;
  height: 1.5rem;
}

.arrowButton {
  background: none;
  border: none;
  margin-left: auto;
  margin-right: 1rem;
}