@media only screen and (max-width: 1200px) {

  .mainWrapper {
    display: flex !important;
    flex-direction: column !important;
    padding: 1.5rem 0px !important;
  }

  .leftWrapper,
  .rightWrapper {
    margin: 0px 1.5rem !important;
    padding: 0px !important;
    gap: 0px !important;
  }

}