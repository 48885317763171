@media only screen and (max-width: 1200px) {

  .mainWrapper {
    display: flex !important;
    padding: 0px !important;
    flex-direction: column-reverse !important;
    height: auto !important;
  }

  .leftWrapper,
  .rightWrapper {
    display: block !important;
    padding: 0px 4rem !important;
  }

  .leftWrapper {
    overflow-y: visible !important;
    height: 100% !important;
  }

  .rightWrapper {
    height: 100% !important;
  }

  .description {
    overflow: visible !important;
    max-height: none !important;
  }

  .contentWrapper {
    height: auto !important;
  }

}