@import "../../global/styles/color.scss";

.mainGridWrapper {
  display: grid;
  height: 100%;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.sectionWrapper {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr;
  margin: 1.5rem;
  gap: 1.5rem;
}