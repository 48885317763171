@import "../../../global/styles/color.scss";

.informationWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.titleWrapper {
  display: inline-block;
  background-color: $blackColor;
  color: $whiteColor;
  padding: 0px 1rem;
  width: fit-content;
  margin: 0.5rem 0px 0px 0px;
}

.informationWrapper>div {
  display: flex;
  flex-direction: column;
}

.previewWrapper {
  width: 50%;
  min-width: 25rem;
}

.agreeWrapper {
  display: flex;
}

