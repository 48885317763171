@import "../../../global/styles/color.scss";

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.wrapper:nth-child(1) {
  flex-grow: 1;
}

.stepWrapper {
  display: flex;
  margin-top: auto;
}

.nextStep,
.prevStep {
  width: 150px;
  height: 40px;
}

.prevStep {
  margin: 0px auto 0px 0px;
  color: $blackColor;
  font-weight: bold;
  border: 0px;
  cursor: pointer;
}

.nextStep {
  margin: 0px 0px 0px auto;
  color: $blackColor;
  font-weight: bold;
  border: 0px;
  cursor: pointer;
}

.disabled {

  font-weight: normal;
}

.notdisabled {

  background-color: $mainColor;
}