@import "./styles/color.scss";
@import "./responsive.module.scss";


// To change the header change rows pixels
// To change navbar width change columns pixels
.mainWrapper {
  height: 100vh;
  display: grid;
  grid-template-rows: 60px auto;
  grid-template-columns: 60px auto;
}

.wrapper {
  background-color: $backgroundColor;
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}