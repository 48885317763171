@import "./../styles/color.scss";

.mainWrapper {
  background-color: $whiteColor;
  width: 100%;
  display: flex;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 100px;
  margin-left: 1rem;
  width: 100%;
}

.imageWrapper {
  margin-right: 2rem;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.imageWrapper>img {
  height: 3rem;
  width: 3rem;
}

.title {
  font-weight: normal;
}