@import "./../styles/color.scss";
@import "./../responsive.module.scss";

.headerWrapper {
  background-color: $mainColor;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
}

.buttonWrapper {
  display: flex;
  justify-content: right;
  height: 100%;
}

.smallWindowNavbar {
  position: fixed;
  width: 100%;
}

.button {
  height: 100%;
  background: none;
  border: 0px;
  margin-right: 0.3rem;
  cursor: pointer;
}

.button>img {
  height: 100%;
  width: 100%;
  padding: 10px;
}

.menuWrapper {
  position: fixed;
  width: 100%;
  z-index: 1;
  transition: 0.2s;
}

.menuWrapperIN {
  top: -720px;
}

.menuWrapperOUT {
  top: 0px;
}

.listWrapper {
  padding: 0px;
  margin: 0px;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 1rem;
  height: 100%;
}

.listWrapper>li {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
}

.listWrapper>li>a {
  width: 100%;
  height: 100%;
}

.listWrapper>li>a>svg {
  width: 100%;
  height: 100%;
  padding: 1.4rem;
}