.card {
  margin-bottom: 2rem;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@keyframes pulse {
  0% {
    background-color: rgba(165, 165, 165, 0.1)
  }

  50% {
    background-color: rgba(165, 165, 165, 0.3)
  }

  100% {
    background-color: rgba(165, 165, 165, 0.1)
  }
}

.customer-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.customer-badge.status-open {
  background-color: #C8E6C9;
  color: #256029;
}

.customer-badge.status-closed {
  background-color: #FFCDD2;
  color: #C63737;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.product-badge.status-instock {
  background: #C8E6C9;
  color: #256029;
}

.product-badge.status-outofstock {
  background: #FFCDD2;
  color: #C63737;
}

.product-badge.status-lowstock {
  background: #FEEDAF;
  color: #8A5340;
}

.order-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;
}

.order-badge.order-delivered {
  background: #C8E6C9;
  color: #256029;
}

.order-badge.order-cancelled {
  background: #FFCDD2;
  color: #C63737;
}

.order-badge.order-pending {
  background: #FEEDAF;
  color: #8A5340;
}

.order-badge.order-returned {
  background: #ECCFFF;
  color: #694382;
}

.image-text {
  vertical-align: middle;
  margin-left: .5rem;
}

.p-multiselect-representative-option {
  display: inline-block;
  vertical-align: middle;
}

.p-multiselect-representative-option img {
  vertical-align: middle;
  width: 3rem;
  height: 3rem;
}

.p-multiselect-representative-option span {
  margin-top: .125rem;
}

.p-column-filter {
  width: 100%;
}

.p-column-title {
  width: 100%;
}

.country-item {
  display: flex;
  align-items: center;
}

.country-item img.flag {
  width: 18px;
  margin-right: .5rem;
}

.flag {
  vertical-align: middle;
}

span.flag {
  width: 44px;
  height: 30px;
  display: inline-block;
}

img.flag {
  width: 30px
}


.arrowButton {
  display: block;
  background: none;
  border: none;
  width: 100%;
  font-size: 32px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .p-datatable[pr_id_1] .p-datatable-tbody>tr>td>.p-column-title {
    display: block;
    display: inline-block;
    width: 120px;
  }
}