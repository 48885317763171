@import "../../global/styles/color.scss";
@import "./responsive.module.scss";

.mainWrapper {
  display: grid;
  grid-template-columns: 1fr 25rem;
  gap: 1.5rem;
  height: 100%;
  padding: 0px 1.5rem;
}

.leftWrapper,
.rightWrapper {
  display: grid;
  padding: 1.5rem 0px;
}

.leftWrapper {
  grid-column-start: 1;
  grid-column-end: 2;
  overflow-y: auto;
}

.rightWrapper {
  grid-column-start: 2;
  grid-column-end: 3;
}

.profileWrapper {
  background: $whiteColor;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.flex {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}