.navbarWrapper {
  margin-top: 20px;
  text-align: right;
}


.navbarWrapper>a {
  text-decoration: none;
  font-size: 16px;
  margin: 0px 35px;
}

.navbarWrapper>a:last-child {
  margin-right: 0px;
}

.onPage {
  text-decoration: underline !important;
}