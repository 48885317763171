@import "../../global/styles/color.scss";
@import "../../global/responsive.module.scss";

.mainWrapper {
  background-color: $whiteColor;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px;
  padding: 10px;
  gap: 10px;
}

.title {
  margin: 0px;
  font-weight: normal;
}

.paragraph {
  margin: 0px;
}

.removeButton {
  background-color: #3E3E3E;
  /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  margin-right: 0px;
  border-radius: 10%;
  cursor: pointer;
}

.sendButton {
  background-color: #3E3E3E;
  /* Green */
  border: none;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  width: 150px;
  height: 30px;
  cursor: pointer;
}

.chipWrapper {
  padding: 7px 10px;
  border-radius: 5px;
}

.responseMessage {
  display: inline-block;
}